import React, {useContext, useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, IconButton, Snackbar, Typography} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AuthContext from "../context/AuthProvider";
import {useParams} from "react-router";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    bgcolor: 'transparent',
    border: 'none',
    boxShadow: "none",
    p: 4,
};

export const PhotoGallery = ({dest}) => {
    const params = useParams();
    const {photos, setPhotos, preview, setPreview, modelName, modelBody} = useContext(AuthContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const addPhoto = () => {
        if(modelName !== '' && modelBody !== '') {
            setPhotos([...photos, {
                photo: null,
            }])
            setPreview([...preview, {
                link: '',
            }])
        }else{
            setSnackbarOpen(true);
        }
    }
    const setPhoto = (event, index) => {
        // const { name } = event.target;
        // console.log(`${params.brand_name.replaceAll(' ', '_')}_${modelName.replaceAll(' ', '_')}_${modelBody.replaceAll(' ', '_')}_${index}${index === 0 ? '_main' : ''}.${event.target.files[0].name.split('.').reverse()[0]}`);
            let photo = new File(
                [event.target.files[0]],
                `${params.brand_name.replaceAll(' ', '_')}_${modelName.replaceAll(' ', '_')}_${modelBody.replaceAll(' ', '_').replaceAll('_/_', '_')}_${index}${index === 0 ? '_main' : ''}.${event.target.files[0].name.split('.').reverse()[0]}`,
                {type: event.target.files[0].type}
            );
            const objectUrl = URL.createObjectURL(event.target.files[0]);
            const list = [...photos];
            const list_preview = [...preview];
            list_preview[index]['link'] = objectUrl;
            list[index]['photo'] = photo;
            setPhotos(list);
            if(preview[index].old_link){
                setOpen(true);
                const formData = new FormData();
                formData.append(photos[index].photo.name, photos[index].photo, photos[index].photo.name);
                axios.post(`https://backend.newozcars.com/cars/update_photo/${preview[index].id}/?file=${preview[index].old_link}`, formData, {headers:{'Content-Type':'multipart/form-data'}}).then(
                    res => {
                        setOpen(false);
                        console.log(list_preview[index])
                        delete list_preview[index]['old_link'];
                        console.log(list_preview[index])
                        setPreview(list_preview);
                    }
                ).catch(
                    err => {console.log(err)}
                )
            }else{
                setPreview(list_preview);
                if (dest === "edit"){
                    const formData = new FormData();
                    formData.append(photos[index].photo.name, photos[index].photo, photos[index].photo.name);
                    axios.post(`https://backend.newozcars.com/cars/load_gallery/${params.model_id}/`, formData, {headers:{'Content-Type':'multipart/form-data'}}).then(
                        res => {
                            console.log(res.data);
                            setOpen(false);
                            console.log(list_preview[index])
                        }
                    ).catch(
                        err => {console.log(err)}
                    )
                }
            }
    }
    const removePhoto = index => {
        let temp = [...photos];
        let preview_temp = [...preview];
        temp.splice(index, 1);
        preview_temp.splice(index, 1);
        setPhotos(temp);
        setPreview(preview_temp);
    }

    const savePhoto = () => {
        console.log(photos);
        console.log(preview);
    }
    const snackbarData = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return(
        <div style={{maxWidth:'1200px', background:"#121212", padding:10, marginLeft:16, marginRight:16, boxSizing:"border-box", borderRadius:4, border: "1px solid rgba(255, 255, 255, 0.12)"}} className={"hide-scrolls"}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message={`Model Name or model body is empty`}
                action={snackbarData}
            />
            <Modal
                open={open}
                // onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CircularProgress color="inherit" />
                    <Typography sx={{mt:2, textAlign:"center"}} id="modal-modal-title" variant="h6" component="h2">
                        Saving in progress<br/> do not refresh page
                    </Typography>
                </Box>
            </Modal>
            <h5 style={{marginLeft: 10, marginTop:5, marginBottom:5}}>Gallery</h5>
            <div style={{display:"flex", overflow:"auto", alignItems:"center"}}>
                {
                    photos.map((photo, index) => {
                        return(
                            <div style={{margin:5, position:"relative"}} key={index}>
                                <input name={'photo'} id={`input${index}`} type={'file'} accept="image/webp" hidden={true} onChange={(event)=>{
                                        setPhoto(event, index)
                                    }
                                    }/>
                                <CloseIcon onClick={()=>{removePhoto(index)
                                    if(preview[index].old_link){
                                        axios.post(`https://backend.newozcars.com/cars/delete_photo/${preview[index].id}/`, {image_path: preview[index].old_link}).then(
                                            res => {console.log(res.data)}
                                        ).catch(
                                            err => {console.log(err)}
                                        )
                                    }
                                }} style={{position:"absolute", right:-10, top:-10}}/>
                                <label htmlFor={`input${index}`}>
                                    {
                                        preview[index].link || preview[index].old_link ?
                                            <img draggable={false} src={preview[index].old_link ? `${preview[index].old_link}` : `${preview[index].link}`} style={{width:150, height:100, borderRadius:4, border: "1px solid rgba(255, 255, 255, 0.12)", padding:5, objectFit:"cover"}}/> :
                                            <div style={{width:150, height:100, borderRadius:4, border: "1px solid rgba(255, 255, 255, 0.12)", padding:5, display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{fontSize:10}}>click to add photo</p></div>
                                    }
                                </label>
                            </div>
                        )
                    })
                }
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:100, height:120, padding:5}}>
                    <Button sx={{marginTop:-1, height:"50px"}} onClick={addPhoto}><AddAPhotoIcon style={{fontSize:42, color:"white"}}/></Button>
                </div>
            </div>
            {/*<Button onClick={savePhoto} style={{textTransform:"none", border:"1px solid rgba(255, 255, 255, 0.12)", color:"white", margin:5}}>Save Photos</Button>*/}
        </div>
    )
}
