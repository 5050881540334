import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {Main} from "./pages/main/Main";
import {Login} from "./pages/login/Login";
import {Model} from "./pages/models/Model";
import {NewModel} from "./pages/new_model/NewModel";
import {EditModel} from "./pages/edit_model/EditModel";

export const useRoutes = (isAuthenticated) => {
    if(isAuthenticated){
        return (
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/:brand_id/:brand_name/" element={<Model />} />
                <Route path="/:brand_id/:brand_name/new_model" element={<NewModel/>} />
                <Route path="/:brand_id/:brand_name/:model_id/:model_name" element={<EditModel/>} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Login />} />
        </Routes>
    )
};
