import LogoutIcon from '@mui/icons-material/Logout';
import {Button} from "@mui/material";
import {useContext, useEffect} from "react";
import axios from "axios";
import AuthContext from "../context/AuthProvider";
export const Header = () => {
    const {me, setMe} = useContext(AuthContext);
    useEffect(()=>{
        axios.get("https://backend.newozcars.com/users/me/").then(
            res => {setMe(res.data)}
        ).catch(err => {console.log(err)})
    },[])

    return(
        <div style={{background:"#121212", position:"fixed", height:50, padding:5, top:0, borderBottom:'1px solid rgba(255, 255, 255, 0.12)',width:"100%", maxWidth:"1200px", alignItems:"center", justifyContent:"space-between", boxSizing:"border-box", zIndex:2}}>
            <header style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginLeft:16, background:"#121212"}}>
                <p style={{fontWeight:600, margin:0, height:20}}>Ozcars</p>
                <div style={{display:"flex", alignItems:"center"}}>
                    <p style={{fontWeight:600, fontSize:14, height:20}}>{me.username}</p>
                    <Button
                        onClick={()=>{localStorage.clear(); window.location.reload()}}
                        sx={{background:"none", border:"none", color:"white", width:2, padding:0}}
                    ><LogoutIcon style={{fontSize:20}}/></Button>
                </div>
            </header>
        </div>

    )
}
