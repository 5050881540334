import {createContext, useEffect, useState} from 'react';
import axios from 'axios';

const AuthContext = createContext({});

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
if (localStorage && localStorage.ozcarsToken) {
    const access_token = localStorage.getItem('ozcarsToken');
    axios.defaults.headers.common['Authorization'] = `Token ${access_token}`;
}
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [me, setMe] = useState({});
    const [modelName, setModelName] = useState('');
    const [modelBody, setModelBody] = useState('');
    const [preview, setPreview] = useState([]);
    const [photos, setPhotos] = useState([]);
    useEffect(() => {
        setAuth(localStorage.getItem('ozcarsToken'));
    }, [localStorage.getItem('ozcarsToken')]);
    return (
        <AuthContext.Provider value={{auth, setAuth, me, setMe, photos, setPhotos, modelName, setModelName, modelBody, setModelBody, preview, setPreview}}>{children}</AuthContext.Provider>
    );
};
export default AuthContext;
