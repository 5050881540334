import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {PhotoGallery} from "../../components/PhotoGallery";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import axios from "axios";
import AuthContext from "../../context/AuthProvider";
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {NumericFormat} from "react-number-format";


// e_torque_nm_front
// e_torque_nm_rear

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    bgcolor: 'transparent',
    border: 'none',
    boxShadow: "none",
    p: 4,
};

export const NewModel = () => {
    const history = useNavigate();
    const params = useParams();
    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    });
    const [finishStatus, setfinishStatus] = useState(false);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Do you want to go back ?")) {
                setfinishStatus(true);
                history(`/${params.brand_id}/${params.brand_name}`);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setfinishStatus(false);
            }
        }
    }
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [comparePhoto, setComparePhoto] = useState(null);
    const [comparePreview, setComparePreview] = useState(null);
    const [backUri, setBackUri] = useState('');
    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);
    const [equipments, setEquipments] = useState([]);
    const {photos, setModelName, setModelBody, setPhotos, setPreview} = useContext(AuthContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setsnackbarMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [model, setModel] = useState({
        name: '',
        body_style: '',
        car_type: '',
        official_site_url: '',
        basic_warranty_years: null,
        basic_warranty_kms: '',
        is_euroncap: false,
        ancap_rate: null,
        adult_pass: null,
        child_pass: null,
        pedestrian: null,
        safety_assist: null,
        ancap_link: '',
    });
    const modelChange = event => {
        if (event.target.type === "number" || event.target.name === "ancap_rate") {
            setModel({...model, [event.target.name]: parseInt(event.target.value)})
        } else {
            setModel({...model, [event.target.name]: event.target.value})
        }
        if (event.target.name === "name") {
            setPhotos([]);
            setPreview([]);
            setModelName(event.target.value);
        }
        if (event.target.name === "body_style") {
            if (event.target.value === 'UTE / Pickup') {
                setIsPickup(true);
            } else {
                setIsPickup(false);
            }
            setPhotos([]);
            setPreview([]);
            setModelBody(event.target.value);
        }
    }
    const addEquipment = () => {
        setEquipments([...equipments, {
            is_archived: false,
            pickup_cab_style: null,
            grade: null,
            grade_index: null,
            engine_index: null,
            engine_code: null,
            car_fuel_type: null,
            engine_fuel_type: null,
            transmiss: null,
            model_year: null,
            price_act: null,
            price_qld: null,
            price_nsw: null,
            price_nt: null,
            price_tas: null,
            price_vic: null,
            price_sa: null,
            price_wa: null,
            seats: null,
            performance: {
                capacity_l: null,
                capacity_cc: null,
                power_kw: null,
                power_rpm: null,
                min_power_rpm: null,
                torque: null,
                torque_min: null,
                torque_max: null,
                cylinder: null,
                valve: null,
                valve_name: null,
                compression: null,
                bore_stroke: null,
                acceleration: null,
                speed: null,
                transmission_type: null,
                gears: null,
                driveline: null,
                ron: null,
                fuel_system_name: null,
                consumption: null,
                tank: null,
                emissions_gkg: null,
                turning_circle: null,
                suspension_front: null,
                suspension_back: null,
                brake_front: null,
                brake_back: null,
                wheels: null,
                tyres: null,
                e_engine_type: null,
                hybrid_type: null,
                e_engine_hp: null,
                e_engine_kw: null,
                e_engine_rpm_min: null,
                e_engine_rpm_max: null,
                e_engine_hp_front: null,
                e_engine_kw_front: null,
                e_engine_rpm_min_front: null,
                e_engine_rpm_max_front: null,
                e_torque_nm_front: null,
                e_engine_hp_rear: null,
                e_engine_kw_rear: null,
                e_engine_rpm_min_rear: null,
                e_engine_rpm_max_rear: null,
                e_torque_nm_rear: null,
                e_torque_nm: null,
                e_torque_rpm_min: null,
                e_torque_rpm_max: null,
                e_total_power: null,
                e_total_torque: null,
                battery_type: null,
                battery_position: null,
                battery_weight: null,
                voltage: null,
                battery_capacity: null,
                battery_capacity_kw: null,
                charge_port: null,
                drive_range: null,
                battery_cells: null,
                energy_consumption: null,
                el_motor_type: null,
                el_front_motor_type: null,
                el_rear_motor_type: null,
                el_transmission: null,
                w_drive_range: null,
                n_drive_range: null,
                el_battery_modules: null,
                ac_charging: null,
                ac_charging_kw: null,
                dc_charging: null,
                dc_charging_kw: null,
                battery_additional: [],
                additional: []
            },
            dimension: {
                length: null,
                length_receiver: null,
                width: null,
                width_mirrors: null,
                width_snorkel: null,
                height: null,
                clearance: null,
                wheel_base: null,
                front_track: null,
                rear_track: null,
                overhang_front: null,
                overhang_back: null,
                angle_front: null,
                angle_back: null,
                angle_ramp: null,
                weight_kerb: null,
                weight_car: null,
                weight_full: null,
                front_axle_weight: null,
                rear_axle_weight: null,
                cargo_capacity: null,
                cargo_capacity_2: null,
                cargo_capacity_3: null,
                towing_capacity_brakes: null,
                towing_capacity: null,
                payload: null,
                headroom_front: null,
                headroom_2nd_row: null,
                legroom_front: null,
                legroom_2nd_row: null,
                shoulder_front: null,
                shoulder_2nd_row: null,
                weding_depth: null,
                towing_capacity_towball: null,
                cargo_box_height: null,
                cargo_box_floor_height_ground: null,
                cargo_box_length: null,
                cargo_box_floor_length: null,
                cargo_box_top_length: null,
                cargo_box_width: null,
                cargo_box_top_width: null,
                cargo_box_floor_width: null,
                cargo_box_width_between_wheel: null,
                cargo_box_opening_width: null,
                additional: []
            },
            interior: [],
            comfort: [],
            exterior: [],
            safety: [],
            options: [],
            packages: [],
        }])
    }
    const setEquipment = (e, index, category) => {
        const list = [...equipments];
        if (category === 'performance') {
            if (e.target.inputMode === "numeric") {
                list[index]['performance'][e.target.name] = parseFloat(e.target.value);
            } else {
                list[index]['performance'][e.target.name] = e.target.value;
            }
        } else if (category === 'dimension') {
            if (e.target.type === "number") {
                list[index]['dimension'][e.target.name] = parseFloat(e.target.value);
            } else if (e.target.type === "text") {
                list[index]['dimension'][e.target.name] = e.target.value;
            } else {
                list[index]['dimension'][e.target.name] = e.target.value;
            }
        } else if (category === '') {
            if (e.target.type === "number") {
                list[index][e.target.name] = parseInt(e.target.value);
            } else if (e.target.type === "text") {
                if (e.target.inputMode === "numeric") {
                    list[index][e.target.name] = parseInt(e.target.value.replaceAll(' ', ''));
                } else {
                    list[index][e.target.name] = e.target.value;
                }
            } else {
                list[index][e.target.name] = e.target.value;
            }
        } else if (category === "archive") {
            list[index][e.target.name] = e.target.checked;
        }
        setEquipments(list);
    }

    const setEquipmentOptions = (e, index, category, count) => {
        const list = [...equipments];
        if (category === 'options') {
            if (e.target.inputMode === "numeric") {
                list[index]['options'][count][e.target.name] = parseInt(e.target.value.replaceAll(' ', ''));
            } else {
                list[index]['options'][count][e.target.name] = e.target.value;
            }
        } else if (category === 'packages') {
            if (e.target.inputMode === "numeric") {
                list[index]['packages'][count][e.target.name] = parseInt(e.target.value.replaceAll(' ', ''));
            } else {
                list[index]['packages'][count][e.target.name] = e.target.value;
            }
        }
        setEquipments(list);
    }
    const setEquipmentAdditional = (e, index, category, additional) => {
        const list = [...equipments];
        if (category === 'performance') {
            list[index]['performance']['additional'][additional][e.target.name] = e.target.value;
        } else if (category === 'battery_additional') {
            list[index]['performance']['battery_additional'][additional][e.target.name] = e.target.value;
        }else if (category === 'dimension') {
            list[index]['dimension']['additional'][additional][e.target.name] = e.target.value;
        } else if (category === 'interior') {
            list[index]['interior'][additional][e.target.name] = e.target.value;
        } else if (category === 'comfort') {
            list[index]['comfort'][additional][e.target.name] = e.target.value;
        } else if (category === 'exterior') {
            list[index]['exterior'][additional][e.target.name] = e.target.value;
        } else if (category === 'safety') {
            list[index]['safety'][additional][e.target.name] = e.target.value;
        } else if (category === 'packages') {
            list[index]['packages'][additional][e.target.name] = e.target.value;
        }
        setEquipments(list);
    }
    const setEquipmentPackagesAdditional = (e, index, category, additional, index_pac) => {
        const list = [...equipments];
        if (category === 'packages') {
            list[index]['packages'][additional]['additional'][index_pac][e.target.name] = e.target.value;
        }
        setEquipments(list);
    }
    const deleteEquipment = index => {
        let temp = [...equipments];
        temp.splice(index, 1);
        setEquipments(temp);
    }
    const copyEquipment = index => {
        let temp = {};
        let dimension_additional = [];
        if (equipments[index].dimension.additional.length > 0) {
            for (let i = 0; i < equipments[index].dimension.additional.length; i++) {
                dimension_additional.push({
                    name: equipments[index].dimension.additional[i].name,
                    value: equipments[index].dimension.additional[i].value,
                })
            }
        }
        let performance_additional = [];
        if (equipments[index].performance.additional.length > 0) {
            for (let i = 0; i < equipments[index].performance.additional.length; i++) {
                performance_additional.push({
                    name: equipments[index].performance.additional[i].name,
                    value: equipments[index].performance.additional[i].value,
                })
            }
        }
        let battery_additional = [];
        if (equipments[index].performance.battery_additional.length > 0) {
            for (let i = 0; i < equipments[index].performance.battery_additional.length; i++) {
                battery_additional.push({
                    name: equipments[index].performance.battery_additional[i].name,
                    value: equipments[index].performance.battery_additional[i].value,
                });
            }
        }
        let options_new = [];
        if (equipments[index].options.length > 0) {
            for (let i = 0; i < equipments[index].options.length; i++) {
                options_new.push({
                    name: equipments[index].options[i].name,
                    cost: equipments[index].options[i].cost,
                })
            }
        }
        let package_new = [];
        if (equipments[index].packages.length > 0) {
            for (let i = 0; i < equipments[index].packages.length; i++) {
                if (equipments[index].packages[i].additional && equipments[index].packages[i].additional.length > 0) {
                    let package_additional = [];
                    for (let j = 0; j < equipments[index].packages[i].additional.length; j++) {
                        package_additional.push({
                            value: equipments[index].packages[i].additional[j].value,
                        });
                    }
                    package_new.push({
                        name: equipments[index].packages[i].name,
                        cost: equipments[index].packages[i].cost,
                        additional: package_additional
                    })
                } else {
                    package_new.push({
                        name: equipments[index].packages[i].name,
                        cost: equipments[index].packages[i].cost,
                        additional: []
                    })
                }
            }
        }
        let interior_new = [];
        if (equipments[index].interior.length > 0) {
            for (let i = 0; i < equipments[index].interior.length; i++) {
                interior_new.push({
                    value: equipments[index].interior[i].value,
                })
            }
        }
        let exterior_new = [];
        if (equipments[index].exterior.length > 0) {
            for (let i = 0; i < equipments[index].exterior.length; i++) {
                exterior_new.push({
                    value: equipments[index].exterior[i].value,
                })
            }
        }
        let comfort_new = [];
        if (equipments[index].comfort.length > 0) {
            for (let i = 0; i < equipments[index].comfort.length; i++) {
                comfort_new.push({
                    value: equipments[index].comfort[i].value,
                })
            }
        }
        let safety_new = [];
        if (equipments[index].safety.length > 0) {
            for (let i = 0; i < equipments[index].safety.length; i++) {
                safety_new.push({
                    value: equipments[index].safety[i].value,
                })
            }
        }
        temp = {
            is_archived: equipments[index].is_archived,
            pickup_cab_style: equipments[index].pickup_cab_style,
            grade: equipments[index].grade,
            grade_index: equipments[index].grade_index,
            engine_index: equipments[index].engine_index,
            engine_code: equipments[index].engine_code,
            car_fuel_type: equipments[index].car_fuel_type,
            engine_fuel_type: equipments[index].engine_fuel_type,
            transmiss: equipments[index].transmiss,
            model_year: equipments[index].model_year,
            price_act: equipments[index].price_act,
            price_qld: equipments[index].price_qld,
            price_nsw: equipments[index].price_nsw,
            price_nt: equipments[index].price_nt,
            price_tas: equipments[index].price_tas,
            price_vic: equipments[index].price_vic,
            price_sa: equipments[index].price_sa,
            price_wa: equipments[index].price_wa,
            seats: equipments[index].seats,
            interior: interior_new,
            comfort: comfort_new,
            exterior: exterior_new,
            safety: safety_new,
            dimension: {
                length: equipments[index].dimension.length,
                length_receiver: equipments[index].dimension.length_receiver,
                width: equipments[index].dimension.width,
                width_mirrors: equipments[index].dimension.width_mirrors,
                width_snorkel: equipments[index].dimension.width_snorkel,
                height: equipments[index].dimension.height,
                clearance: equipments[index].dimension.clearance,
                wheel_base: equipments[index].dimension.wheel_base,
                front_track: equipments[index].dimension.front_track,
                rear_track: equipments[index].dimension.rear_track,
                overhang_front: equipments[index].dimension.overhang_front,
                overhang_back: equipments[index].dimension.overhang_back,
                angle_front: equipments[index].dimension.angle_front,
                angle_back: equipments[index].dimension.angle_back,
                angle_ramp: equipments[index].dimension.angle_ramp,
                weight_kerb: equipments[index].dimension.weight_kerb,
                weight_car: equipments[index].dimension.weight_car,
                weight_full: equipments[index].dimension.weight_full,
                front_axle_weight: equipments[index].dimension.front_axle_weight,
                rear_axle_weight: equipments[index].dimension.rear_axle_weight,
                cargo_capacity: equipments[index].dimension.cargo_capacity,
                cargo_capacity_2: equipments[index].dimension.cargo_capacity_2,
                cargo_capacity_3: equipments[index].dimension.cargo_capacity_3,
                towing_capacity_brakes: equipments[index].dimension.towing_capacity_brakes,
                towing_capacity: equipments[index].dimension.towing_capacity,
                payload: equipments[index].dimension.payload,
                headroom_front: equipments[index].dimension.headroom_front,
                headroom_2nd_row: equipments[index].dimension.headroom_2nd_row,
                legroom_front: equipments[index].dimension.legroom_front,
                legroom_2nd_row: equipments[index].dimension.legroom_2nd_row,
                shoulder_front: equipments[index].dimension.shoulder_front,
                shoulder_2nd_row: equipments[index].dimension.shoulder_2nd_row,
                weding_depth: equipments[index].dimension.weding_depth,
                towing_capacity_towball: equipments[index].dimension.towing_capacity_towball,
                cargo_box_height: equipments[index].dimension.cargo_box_height,
                cargo_box_floor_height_ground: equipments[index].dimension.cargo_box_floor_height_ground,
                cargo_box_length: equipments[index].dimension.cargo_box_length,
                cargo_box_floor_length: equipments[index].dimension.cargo_box_floor_length,
                cargo_box_top_length: equipments[index].dimension.cargo_box_top_length,
                cargo_box_width: equipments[index].dimension.cargo_box_width,
                cargo_box_top_width: equipments[index].dimension.cargo_box_top_width,
                cargo_box_floor_width: equipments[index].dimension.cargo_box_floor_width,
                cargo_box_width_between_wheel: equipments[index].dimension.cargo_box_width_between_wheel,
                cargo_box_opening_width: equipments[index].dimension.cargo_box_opening_width,
                additional: dimension_additional
            },
            performance: {
                ron: equipments[index].performance.ron,
                capacity_l: equipments[index].performance.capacity_l,
                capacity_cc: equipments[index].performance.capacity_cc,
                power_rpm: equipments[index].performance.power_rpm,
                min_power_rpm: equipments[index].performance.min_power_rpm,
                power_kw: equipments[index].performance.power_kw,
                torque: equipments[index].performance.torque,
                torque_min: equipments[index].performance.torque_min,
                torque_max: equipments[index].performance.torque_max,
                cylinder: equipments[index].performance.cylinder,
                valve: equipments[index].performance.valve,
                valve_name: equipments[index].performance.valve_name,
                compression: equipments[index].performance.compression,
                bore_stroke: equipments[index].performance.bore_stroke,
                acceleration: equipments[index].performance.acceleration,
                speed: equipments[index].performance.speed,
                transmission_type: equipments[index].performance.transmission_type,
                gears: equipments[index].performance.gears,
                driveline: equipments[index].performance.driveline,
                fuel_system_name: equipments[index].performance.fuel_system_name,
                consumption: equipments[index].performance.consumption,
                tank: equipments[index].performance.tank,
                emissions_gkg: equipments[index].performance.emissions_gkg,
                turning_circle: equipments[index].performance.turning_circle,
                suspension_front: equipments[index].performance.suspension_front,
                suspension_back: equipments[index].performance.suspension_back,
                brake_front: equipments[index].performance.brake_front,
                brake_back: equipments[index].performance.brake_back,
                wheels: equipments[index].performance.wheels,
                tyres: equipments[index].performance.tyres,
                e_engine_type: equipments[index].performance.e_engine_type,
                hybrid_type: equipments[index].performance.hybrid_type,
                e_engine_hp: equipments[index].performance.e_engine_hp,
                e_engine_kw: equipments[index].performance.e_engine_kw,
                e_engine_rpm_min: equipments[index].performance.e_engine_rpm_min,
                e_engine_rpm_max: equipments[index].performance.e_engine_rpm_max,
                e_engine_hp_front: equipments[index].performance.e_engine_hp_front,
                e_engine_kw_front: equipments[index].performance.e_engine_kw_front,
                e_engine_rpm_min_front: equipments[index].performance.e_engine_rpm_min_front,
                e_engine_rpm_max_front: equipments[index].performance.e_engine_rpm_max_front,
                e_torque_nm_front: equipments[index].performance.e_torque_nm_front,
                e_engine_hp_rear: equipments[index].performance.e_engine_hp_rear,
                e_engine_kw_rear: equipments[index].performance.e_engine_kw_rear,
                e_engine_rpm_min_rear: equipments[index].performance.e_engine_rpm_min_rear,
                e_engine_rpm_max_rear: equipments[index].performance.e_engine_rpm_max_rear,
                e_torque_nm_rear: equipments[index].performance.e_torque_nm_rear,
                e_torque_nm: equipments[index].performance.e_torque_nm,
                e_torque_rpm_min: equipments[index].performance.e_torque_rpm_min,
                e_torque_rpm_max: equipments[index].performance.e_torque_rpm_max,
                e_total_power: equipments[index].performance.e_total_power,
                e_total_torque: equipments[index].performance.e_total_torque,
                battery_type: equipments[index].performance.battery_type,
                battery_position: equipments[index].performance.battery_position,
                battery_weight: equipments[index].performance.battery_weight,
                voltage: equipments[index].performance.voltage,
                battery_capacity: equipments[index].performance.battery_capacity,
                battery_capacity_kw: equipments[index].performance.battery_capacity_kw,
                charge_port: equipments[index].performance.charge_port,
                drive_range: equipments[index].performance.drive_range,
                battery_cells: equipments[index].performance.battery_cells,
                energy_consumption: equipments[index].performance.energy_consumption,
                el_motor_type: equipments[index].performance.el_motor_type,
                el_front_motor_type: equipments[index].performance.el_front_motor_type,
                el_rear_motor_type: equipments[index].performance.el_rear_motor_type,
                el_transmission: equipments[index].performance.el_transmission,
                w_drive_range: equipments[index].performance.w_drive_range,
                n_drive_range: equipments[index].performance.n_drive_range,
                el_battery_modules: equipments[index].performance.el_battery_modules,
                ac_charging: equipments[index].performance.ac_charging,
                ac_charging_kw: equipments[index].performance.ac_charging_kw,
                dc_charging: equipments[index].performance.dc_charging,
                dc_charging_kw: equipments[index].performance.dc_charging_kw,
                battery_additional: battery_additional,
                additional: performance_additional,
            },
            options: options_new,
            packages: package_new,
        }
        let temp_data = [...equipments]
        temp_data.push(temp)
        setEquipments(temp_data);
    }

    const submitData = event => {
        setOpen(true);
        event.preventDefault();
        console.log(equipments);
        const formData = new FormData();
        for (let file = 0; file < photos.length; file++) {
            formData.append(photos[file].photo.name, photos[file].photo, photos[file].photo.name);
        }
        axios.post("https://backend.newozcars.com/cars/create_model/", {
            ...model,
            brand_id: params.brand_id,
            equipments: [...equipments]
        }).then(
            res => {
                if (comparePhoto) {
                    const compareForm = new FormData();
                    compareForm.append("name", `${params.brand_name}_${model.name}_compare_photo.webp`);
                    compareForm.append("compare_image", comparePhoto);
                    axios.post(`https://backend.newozcars.com/cars/upload_compare_photo/${res.data.model_id}/`, compareForm, {headers: {'Content-Type': 'multipart/form-data'}}).then(
                        res => {
                            console.log(res.data);
                        }
                    ).catch(
                        err => {
                            console.log(err)
                            setSnackbarOpen(true);
                            setsnackbarMessage(err.response.data.message);
                        }
                    )
                }
                if (photos.length > 0) {
                    axios.post(`https://backend.newozcars.com/cars/load_gallery/${res.data.model_id}/`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(
                        res => {
                            console.log(res.data);
                            history(`/${params.brand_id}/${params.brand_name}`);
                        }
                    ).catch(
                        err => {
                            console.log(err)
                            setSnackbarOpen(true);
                            setsnackbarMessage(err.response.data.message);
                        }
                    )
                } else {
                    history(`/${params.brand_id}/${params.brand_name}`);
                }
            }
        ).catch(
            err => {
                setSnackbarOpen(true);
                setsnackbarMessage(err.response.data.message);
            }
        )
    }
    const [isPickup, setIsPickup] = useState(false);
    const snackbarData = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );
    const myList = [
        {name: "one", color: "green"},
        {name: "two", color: "blue"},
        {name: "three", color: "red"},
        {name: "four", color: "lightgreen"},
        {name: "five", color: "lightblue"},
        {name: "six", color: "cyan"}
    ];
    return (
        <form onSubmit={submitData} style={{position: "relative"}} className={'hide-scrolls'}>
            <button type="submit" disabled style={{display: "none"}} aria-hidden="true"></button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message={`${snackbarMessage}`}
                action={snackbarData}
            />
            <Modal
                open={openConfirmModal}
                sx={{background: "black"}}
                onClose={handleCloseConfirmModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{margin: 0, fontSize: 14, fontWeight: 400, textAlign: "center"}}>Go back are you
                        sure?</h2>
                    <Button
                        onClick={() => {
                            history(backUri)
                        }}
                        sx={{
                            mt: 2,
                            width: "100%",
                            background: "red",
                            color: "white",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 400,
                            "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.12) !important"
                            }
                        }}
                    >Yes</Button>
                    <Button
                        onClick={handleCloseConfirmModal}
                        sx={{
                            mt: 2,
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.12)",
                            color: "white",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 400,

                        }}
                    >No</Button>
                </Box>
            </Modal>
            <Modal
                open={open}
                // onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CircularProgress color="inherit"/>
                    <Typography sx={{mt: 2, textAlign: "center"}} id="modal-modal-title" variant="h6" component="h2">
                        Saving in progress<br/> do not refresh page
                    </Typography>
                </Box>
            </Modal>
            <div style={{
                display: 'flex',
                maxWidth: '1200px',
                width: "100%",
                justifyContent: 'space-between',
                boxSizing: "border-box",
                position: "fixed",
                top: 50,
                paddingTop: 10,
                paddingBottom: 10,
                background: "#121212",
                zIndex: 2
            }}>
                <p style={{marginTop: 8, marginBottom: 8, marginLeft: 22}}><span onClick={() => {
                    setBackUri('/');
                    setOpenConfirmModal(true);
                }} style={{color: "white", cursor: "pointer", textDecoration: "underline"}}>Main</span> / <span
                    onClick={() => {
                        setBackUri(`/${params.brand_id}/${params.brand_name}`);
                        setOpenConfirmModal(true);
                    }} style={{
                    color: "white",
                    cursor: "pointer",
                    textDecoration: "underline"
                }}>{params.brand_name}</span> / New Model<span></span></p>
                <Button type={"submit"} sx={{
                    background: "none",
                    textTransform: "none",
                    color: "white",
                    border: "1px solid rgba(255, 255, 255, 0.12)",
                    marginRight: 3
                }}>Save</Button>
            </div>
            <div style={{
                maxWidth: '1200px',
                background: "#121212",
                padding: 10,
                marginLeft: 16,
                marginRight: 16,
                boxSizing: "border-box",
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.12)",
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly"
            }}>
                <div style={{width: "50%", padding: 10, boxSizing: 'border-box', minWidth: 300}}>
                    <h4 style={{marginTop: 28, marginBottom: 5}}>General Information</h4>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>model (Модель автомобиля)</label>
                        <input required={true} onChange={modelChange} name={"name"} value={model.name}
                               onWheel={(event) => {
                                   event.currentTarget.blur()
                               }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>URL (Ссылка на авто)</label>
                        <input required={true} onChange={modelChange} name={"official_site_url"}
                               value={model.official_site_url}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>warranty, years (Гарантия в годах)</label>
                        <input required={true} onChange={modelChange} type={"number"} name={"basic_warranty_years"}
                               value={model.basic_warranty_years} onWheel={(event) => {
                            event.currentTarget.blur()
                        }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>warranty, km (Гарантия в километрах)</label>
                        <input required={true} onChange={modelChange} name={"basic_warranty_kms"}
                               value={model.basic_warranty_kms}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>body (Кузов автомобиля)</label>
                        <select required={true} onChange={modelChange} name={"body_style"} value={model.body_style}
                                style={{
                                    width: "100%",
                                    padding: 6,
                                    boxSizing: 'border-box',
                                    borderRadius: 6,
                                    background: "rgba(255,255,255,0.15)",
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    color: 'white'
                                }}>
                            <option></option>
                            <option>Convertible</option>
                            <option>Coupe</option>
                            <option>Hatch</option>
                            <option>People Mover</option>
                            <option>Sedan</option>
                            <option>Sports</option>
                            <option>SUV</option>
                            <option>UTE / Pickup</option>
                            <option>Cab chassis</option>
                            <option>VAN</option>
                            <option>Wagon</option>
                        </select>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>type (Тип транспортного средства)</label>
                        <select required={true} onChange={modelChange} name={"car_type"} value={model.car_type}
                                style={{
                                    width: "100%",
                                    padding: 6,
                                    boxSizing: 'border-box',
                                    borderRadius: 6,
                                    background: "rgba(255,255,255,0.15)",
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    color: 'white'
                                }}>
                            <option></option>
                            <option>Passenger</option>
                            <option>Commercial</option>
                        </select>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10, display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <p>фото машины передом для раздела</p> (comparison) <Tooltip title={
                            <div style={{width: 150, height: 100}}>
                                <img draggable={false} style={{width: 150, height: 100}}
                                     src={"https://newozcars.com/_next/image?url=https%3A%2F%2Fbackend.newozcars.com%2Fmedia%2Fgallery%2FFord_Ranger_UTE_Pickup_8.webp&w=3840&q=90"}/>
                            </div>
                        } placement="bottom">
                            <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                        </Tooltip></label>
                        <div style={{marginTop: 10}}>
                            <input name={'photo'} id={`compare_photo`} type={'file'} accept="image/webp" hidden={true}
                                   onChange={(event) => {
                                       setComparePreview(URL.createObjectURL(event.target.files[0]))
                                       const new_file = new File(
                                           [event.target.files[0]],
                                           `${params.brand_name}_${model.name}_compare_photo.webp`,
                                           {type: event.target.files[0].type}
                                       );
                                       setComparePhoto(new_file);
                                   }
                                   }/>
                            <label htmlFor={`compare_photo`}>
                                {
                                    comparePreview ?
                                        <img draggable={false} src={comparePreview}
                                             style={{width: 150, height: 100, borderRadius: 4}}/> :
                                        <div style={{
                                            width: 150,
                                            height: 100,
                                            borderRadius: 4,
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            padding: 5,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}><p style={{fontSize: 10}}>click to add photo</p></div>
                                }
                            </label>
                        </div>
                    </div>
                </div>
                <div style={{width: "50%", padding: 10, boxSizing: 'border-box', minWidth: 300}}>
                    <h4 style={{marginTop: 5, marginBottom: 5}}>Ancap</h4>
                    <div>
                        EuroNcap?<input onChange={(e) => {
                        setModel({...model, is_euroncap: e.target.checked})
                    }} type={"checkbox"}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Ancap url (Ссылка на Ancap)</label>
                        <input required={true} onChange={modelChange} name={"ancap_link"} value={model.ancap_link}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Adult Occupant Protection</label>
                        <input required={true} type={"number"} onChange={modelChange} name={"adult_pass"}
                               value={model.adult_pass} onWheel={(event) => {
                            event.currentTarget.blur()
                        }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Child Occupant Protection</label>
                        <input required={true} type={"number"} onChange={modelChange} name={"child_pass"}
                               value={model.child_pass} onWheel={(event) => {
                            event.currentTarget.blur()
                        }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Vulnerable Road User Protection</label>
                        <input required={true} type={"number"} onChange={modelChange} name={"pedestrian"}
                               value={model.pedestrian} onWheel={(event) => {
                            event.currentTarget.blur()
                        }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Safety Assist</label>
                        <input required={true} type={"number"} onChange={modelChange} name={"safety_assist"}
                               value={model.safety_assist} onWheel={(event) => {
                            event.currentTarget.blur()
                        }}
                               style={{
                                   width: "100%",
                                   padding: 6,
                                   boxSizing: 'border-box',
                                   borderRadius: 6,
                                   background: "rgba(255,255,255,0.15)",
                                   border: "1px solid rgba(255, 255, 255, 0.12)",
                                   color: 'white'
                               }}/>
                    </div>
                    <div style={{marginTop: 10, width: "100%"}}>
                        <label style={{fontSize: 10}}>Ancap stars (Количество звезд)</label>
                        <select required={true} onChange={modelChange} name={"ancap_rate"} value={model.ancap_rate}
                                style={{
                                    width: "100%",
                                    padding: 6,
                                    boxSizing: 'border-box',
                                    borderRadius: 6,
                                    background: "rgba(255,255,255,0.15)",
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    color: 'white'
                                }}>
                            <option></option>
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>
            <PhotoGallery/>
            <div style={{
                maxWidth: '1200px',
                background: "#121212",
                padding: 10,
                marginLeft: 16,
                marginRight: 16,
                boxSizing: "border-box",
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.12)",
                marginBottom: 20,
                marginTop: 20,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start"
            }}>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <h4 style={{marginTop: 5, marginBottom: 5, marginLeft: 8}}>Equipments</h4>
                    <Button sx={{
                        background: "none",
                        textTransform: "none",
                        color: "white",
                        border: "1px solid rgba(255, 255, 255, 0.12)"
                    }} onClick={addEquipment}>Add new equipment</Button>
                </div>
                <div style={{overflow: "auto", display: "flex", maxWidth: 1200, width: "100%"}}>
                    {
                        equipments.map((equipment, index) => {
                            return (
                                <div key={index} style={{
                                    width: '300px',
                                    minWidth: '300px',
                                    background: "#121212",
                                    padding: 10,
                                    marginLeft: 16,
                                    marginRight: 16,
                                    boxSizing: "border-box",
                                    borderRadius: 4,
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    marginBottom: 20,
                                    marginTop: 20,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly"
                                }}>
                                    <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                            <h4 style={{marginTop: 5, marginBottom: 5}}>Equipment</h4>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <Button
                                                    sx={{margin: 0, height: 40, color: "white", textTransform: "none"}}
                                                    onClick={() => {
                                                        copyEquipment(index)
                                                    }}><p style={{marginRight: 5}}>Copy</p><ContentCopyIcon
                                                    style={{width: 15}}/></Button>
                                                <CloseIcon onClick={() => {
                                                    deleteEquipment(index)
                                                }}/>
                                            </div>
                                        </div>
                                        <div>
                                            добавить в архив?<input onChange={(event) => {
                                            setEquipment(event, index, 'archive')
                                        }} defaultChecked={false} name={"is_archived"} type={"checkbox"}
                                                                    value={equipments[index].is_archived}/>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Pickup Cab Style</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"pickup_cab_style"}
                                                        value={equipments[index].pickup_cab_style}>
                                                    <option></option>
                                                    <option>No</option>
                                                    <option>Double Cab</option>
                                                    <option>Extra Cab</option>
                                                    <option>Single Cab</option>
                                                </select>
                                                <Tooltip title="Тип кабины пикапа" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Grade Index</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <input
                                                    placeholder={"for example: 350h"}
                                                    type={"text"} required={true}
                                                    className={"input-common"}
                                                    value={equipments[index].grade_index}
                                                    name={"grade_index"}
                                                    onWheel={(event) => {
                                                        event.currentTarget.blur()
                                                    }}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}/>
                                                <Tooltip title="Название комплектации" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Grade</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <input

                                                    placeholder={"for example: SR5"}
                                                    type={"text"} required={true}
                                                    className={"input-common"}
                                                    value={equipments[index].grade}
                                                    name={"grade"}
                                                    onWheel={(event) => {
                                                        event.currentTarget.blur()
                                                    }}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}/>
                                                <Tooltip title="Название комплектации" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, ACT Canberra 2601</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_act}
                                                    name={"price_act"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате ACT, Australia capital territory"
                                                         placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, QLD Brisbane 4000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_qld}
                                                    name={"price_qld"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате QLD, Queensland" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, NSW Sydney 2000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_nsw}
                                                    name={"price_nsw"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате NSW, New South Wales" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, NT Darwin 0810</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_nt}
                                                    name={"price_nt"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате NT, Northern Territory" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, TAS Hobart 7000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_tas}
                                                    name={"price_tas"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате TAS, Tasmania" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, VIC Melbourne 3000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_vic}
                                                    name={"price_vic"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате VIC, Victoria" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, SA Adelaide 5000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_sa}
                                                    name={"price_sa"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате SA, South Australia" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 14}}>Price, WA Perth 6000</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    className={"input-common"}
                                                    placeholder={"for example: 100 000"}

                                                    value={equipments[index].price_wa}
                                                    name={"price_wa"}
                                                    type="text" required={true}
                                                    thousandSeparator=" "
                                                    onChange={(event) => {
                                                        setEquipment(event, index, '')
                                                    }}
                                                />
                                                <Tooltip title="цена в штате WA, Western Australia" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Engine Index</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <input placeholder={"for example: SR5"} type={"text"} required={true}
                                                       className={"input-common"} value={equipments[index].engine_index}
                                                       name={"engine_index"} onWheel={(event) => {
                                                    event.currentTarget.blur()
                                                }} onChange={(event) => {
                                                    setEquipment(event, index, '')
                                                }}/>
                                                <Tooltip title="Индекс двигателя" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Engine Code</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <input placeholder={"for example: SR5"} type={"text"} required={true}
                                                       className={"input-common"} value={equipments[index].engine_code}
                                                       name={"engine_code"} onWheel={(event) => {
                                                    event.currentTarget.blur()
                                                }} onChange={(event) => {
                                                    setEquipment(event, index, '')
                                                }}/>
                                                <Tooltip title="Индекс двигателя" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Car fuel type</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"car_fuel_type"}
                                                        value={equipments[index].car_fuel_type}>
                                                    <option></option>
                                                    <option>Petrol</option>
                                                    <option>Diesel</option>
                                                    <option>Hybrid (MHEV)</option>
                                                    <option>Hybrid (HEV)</option>
                                                    <option>Hybrid (PHEV)</option>
                                                    <option>Hybrid (REV)</option>
                                                    <option>Electric</option>
                                                    <option>LPG</option>
                                                </select>
                                                <Tooltip title="Тип топлива машины" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Engine fuel type</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"engine_fuel_type"}
                                                        value={equipments[index].engine_fuel_type}>
                                                    <option></option>
                                                    <option>Petrol</option>
                                                    <option>Diesel</option>
                                                    <option>Electric</option>
                                                    <option>LPG</option>
                                                </select>
                                                <Tooltip title="Тип топлива мотора" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Transmission</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"transmiss"} value={equipments[index].transmiss}>
                                                    <option></option>
                                                    <option>Automatic</option>
                                                    <option>Manual</option>
                                                </select>
                                                <Tooltip title="Тип трансмиссии" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Model Year</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"model_year"} value={equipments[index].model_year}>
                                                    <option></option>
                                                    <option>2022</option>
                                                    <option>2023</option>
                                                    <option>2024</option>
                                                </select>
                                                <Tooltip title="Модельный год" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Seats</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <select required={true} className={'select-common'}
                                                        onChange={(event) => {
                                                            setEquipment(event, index, '')
                                                        }} name={"seats"} value={equipments[index].seats}>
                                                    <option></option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                </select>
                                                <Tooltip title="Количество мест" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        {/*Perfomance*/}
                                        {
                                            equipments[index].car_fuel_type && equipments[index].car_fuel_type.includes('Electric') ?
                                                <>
                                                    <h4 style={{
                                                        marginTop: 20,
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}><span>Electric options</span></h4>
                                                    <div style={{
                                                        width: "100%",
                                                        height: "20px",
                                                        background: 'blue'
                                                    }}></div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Motor type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"Three Phase Permanent Magnet Synchronous Motor"}
                                                                type={"text"} required={true}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.el_motor_type}
                                                                name={"el_motor_type"} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип Электро Мотора" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine Power, kW</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.power_kw}
                                                                name={"power_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, kW"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Torque, Nm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 250"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.torque}
                                                                name={"torque"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Крутящий момент" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Driveline</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <select required={true} className={'select-common'}
                                                                    onChange={(event) => {
                                                                        setEquipment(event, index, 'performance')
                                                                    }} name={"driveline"}
                                                                    value={equipments[index].performance.driveline}>
                                                                <option></option>
                                                                <option>AWD</option>
                                                                <option>GR-Four AWD</option>
                                                                <option>i-Activ AWD</option>
                                                                <option>FWD</option>
                                                                <option>RWD</option>
                                                                <option>2WD</option>
                                                                <option>4WD</option>
                                                                <option>4x4</option>
                                                                <option>4x2</option>
                                                                <option>4x2 Hi-Rider (RWD)</option>
                                                                <option>Part-time 4x4</option>
                                                                <option>Full-time 4WD</option>
                                                                <option>Quattro</option>
                                                                <option>4MATIC</option>
                                                            </select>
                                                            <Tooltip title="Привод" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front motor type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"Three Phase Permanent Magnet Synchronous Motor"}
                                                                type={"text"} required={true}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.el_front_motor_type}
                                                                name={"el_front_motor_type"} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип переднего мотора" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front motor maximum power
                                                            (kW)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_kw_front}
                                                                name={"e_engine_kw_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность переднего электромотора, kW"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front motor maximum torque
                                                            (Nm)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 250"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_nm_front}
                                                                name={"e_torque_nm_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Передний крутящий момент"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear motor type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"Three Phase Permanent Magnet Synchronous Motor"}
                                                                type={"text"} required={true}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.el_rear_motor_type}
                                                                name={"el_rear_motor_type"} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип заднего мотора" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear motor maximum power
                                                            (kW)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_kw_rear}
                                                                name={"e_engine_kw_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность заднего электромотора, kW"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear motor maximum torque
                                                            (Nm)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 250"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_nm_rear}
                                                                name={"e_torque_nm_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Задний крутящий момент" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Energy consumption, kWh/100 km(WLTP)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 560"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.energy_consumption}
                                                                name={"energy_consumption"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="потребление энергии" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Acceleration 0-100, sec</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 9.0"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.acceleration}
                                                                name={"acceleration"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Разгон до 100 в секундах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Max. Speed, km/h</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 200"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.speed}
                                                                name={"speed"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Максимальная скорость в км в час"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Driving range: WLTP Combined
                                                            (km)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.w_drive_range}
                                                                name={"w_drive_range"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Расстояние на одном заряде, km (WLTP)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Driving range: NEDC (km)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.n_drive_range}
                                                                name={"n_drive_range"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Расстояние на одном заряде, km (NEDC)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Transmission</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"Single Speed Transmission (Reduction Gear)"}
                                                                type={"text"} required={true}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.el_transmission}
                                                                name={"el_transmission"} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Transmission" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Turning circle – kerb to kerb,
                                                            m</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 12"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.turning_circle}
                                                                name={"turning_circle"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Диаметр разворота" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Suspension</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: coil springs"}
                                                                   type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.suspension_front}
                                                                   name={"suspension_front"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Передняя подвеска" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Suspension</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: leaf springs"}
                                                                   type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.suspension_back}
                                                                   name={"suspension_back"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Задняя подвеска" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Brakes</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: drum brakes"}
                                                                   type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.brake_front}
                                                                   name={"brake_front"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Передние тормоза" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Brakes</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: drum brakes"}
                                                                   type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.brake_back}
                                                                   name={"brake_back"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Задние тормоза" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Wheels</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"for example: 16' Dark Anthracite Alloy Wheel"}
                                                                type={"text"} required={true} className={"input-common"}
                                                                value={equipments[index].performance.wheels}
                                                                name={"wheels"}
                                                                onWheel={(event) => {
                                                                    event.currentTarget.blur()
                                                                }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Колеса" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Tyres</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: 265/70 R16"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.tyres}
                                                                   name={"tyres"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Шины" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    {
                                                        equipments[index].performance.additional.map((additional, count) => {
                                                            return (
                                                                <div key={count} style={{
                                                                    display: "flex",
                                                                    marginTop: 10,
                                                                    alignItems: "center"
                                                                }}>
                                                                    <div>
                                                                        <label style={{fontSize: 10}}>Additional</label>
                                                                        <div style={{display: "flex"}}>
                                                                            <input required={true} name={'name'}
                                                                                   value={equipments[index].performance.additional[count].name}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'performance', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white',
                                                                                marginRight: 10
                                                                            }}/>
                                                                            <input required={true} name={'value'}
                                                                                   value={equipments[index].performance.additional[count].value}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'performance', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white'
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                    <Tooltip
                                                                        title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                                        placement="bottom">
                                                                        <QuestionMarkIcon
                                                                            sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                                    </Tooltip>
                                                                    <DeleteIcon sx={{marginTop: 2, marginLeft: 1}}
                                                                                onClick={() => {
                                                                                    let temp = [...equipments];
                                                                                    temp[index].performance.additional.splice(count, 1);
                                                                                    setEquipments(temp);
                                                                                }}/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <Button sx={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        border: "1px solid rgba(255, 255, 255, 0.12)",
                                                        marginTop: 2
                                                    }} onClick={() => {
                                                        let temp = [...equipments];
                                                        temp[index].performance.additional.push({
                                                            name: "",
                                                            value: ""
                                                        });
                                                        setEquipments(temp);
                                                    }}>Add Additional</Button>
                                                    <h4 style={{marginTop: 20, marginBottom: 5}}> Battery </h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"lithium-ion batteries"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.battery_type}
                                                                   name={"battery_type"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип батареи" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Position</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"under the rear passenger seats"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.battery_position}
                                                                   name={"battery_position"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Положение батареи" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Weight, kg</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_weight}
                                                                name={"battery_weight"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Вес батареи" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery modules</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.el_battery_modules}
                                                                name={"el_battery_modules"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Вес батареи" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Voltage, V</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 550"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.voltage}
                                                                name={"voltage"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Напряжение, В" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Capacity, kWh</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 450"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_capacity_kw}
                                                                name={"battery_capacity_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Емкость, kW" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery capacity (Ahr)</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 450"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_capacity}
                                                                name={"battery_capacity"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Энергия, ahr" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>AC charging – 10-100%,
                                                            Hours</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"9 "}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.ac_charging}
                                                                name={"ac_charging"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Время Стандартной зарядки"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>AC charging – 10-100%,
                                                            kw</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"9 "}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.ac_charging_kw}
                                                                name={"ac_charging_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="мощность стандартной  зарядки"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>DC charging – 10-100%,
                                                            mins</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"9 "}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.dc_charging}
                                                                name={"dc_charging"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="время мощной зарядки"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>DC charging – 10-100%,
                                                            kw</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"9 "}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.dc_charging_kw}
                                                                name={"dc_charging_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="мощность быстрой зарядки зарядки"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    {
                                                        equipments[index].performance.battery_additional.map((additional, count) => {
                                                            return (
                                                                <div key={count} style={{
                                                                    display: "flex",
                                                                    marginTop: 10,
                                                                    alignItems: "center"
                                                                }}>
                                                                    <div>
                                                                        <label style={{fontSize: 10}}>Battery Additional</label>
                                                                        <div style={{display: "flex"}}>
                                                                            <input required={true} name={'name'}
                                                                                   value={equipments[index].performance.battery_additional[count].name}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'battery_additional', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white',
                                                                                marginRight: 10
                                                                            }}/>
                                                                            <input required={true} name={'value'}
                                                                                   value={equipments[index].performance.battery_additional[count].value}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'battery_additional', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white'
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                    <Tooltip
                                                                        title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                                        placement="bottom">
                                                                        <QuestionMarkIcon
                                                                            sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                                    </Tooltip>
                                                                    <DeleteIcon sx={{marginTop: 2, marginLeft: 1}}
                                                                                onClick={() => {
                                                                                    let temp = [...equipments];
                                                                                    temp[index].performance.battery_additional.splice(count, 1);
                                                                                    setEquipments(temp);
                                                                                }}/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <Button sx={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        border: "1px solid rgba(255, 255, 255, 0.12)",
                                                        marginTop: 2
                                                    }} onClick={() => {
                                                        let temp = [...equipments];
                                                        temp[index].performance.battery_additional.push({
                                                            name: "",
                                                            value: ""
                                                        });
                                                        setEquipments(temp);
                                                    }}>Add Battery Additional</Button>
                                                </>
                                                :
                                                <>
                                                    <h4 style={{marginTop: 20, marginBottom: 5}}>Performance</h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine capacity, L</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 3,2"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.capacity_l}
                                                                name={"capacity_l"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Объем двигателя в литрах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine capacity, cc</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2998"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.capacity_cc}
                                                                name={"capacity_cc"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Объем двигателя в кубических сантиметрах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine Power, kW</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 230"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.power_kw}
                                                                name={"power_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность двигателя в килоВваттах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine min. Power, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 3600"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.min_power_rpm}
                                                                name={"min_power_rpm"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Двигатель мин. Мощность, об/мин"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Engine max. Power, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 3600"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.power_rpm}
                                                                name={"power_rpm"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Максимальные обороты двигателя"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Torque, Nm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 250"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.torque}
                                                                name={"torque"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Крутящий момент" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Min. Torque, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2000"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.torque_min}
                                                                name={"torque_min"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Минимальные обороты двигателя, при которых достигается максимальный крутящий момент. Если одно значение пиши сюда"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Max. Torque, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 5000"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.torque_max}
                                                                name={"torque_max"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Максимальные обороты двигателя, при которых достигается максимальный крутящий момент."
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Number of cylinders</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 4"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.cylinder}
                                                                name={"cylinder"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Количество цилиндров" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Number of valves</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 16"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.valve}
                                                                name={"valve"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Количество клапанов" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Name of valve mechanism</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: DOHC, chain drive"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.valve_name}
                                                                   name={"valve_name"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Название системы клапанов"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Compression ratio</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: 10.3 : 1"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.compression}
                                                                   name={"compression"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Компрессия" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Bore x Stroke, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: 80.5 x 97.6"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.bore_stroke}
                                                                   name={"bore_stroke"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Диаметр и Ход поршня" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Acceleration 0-100, sec</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 9.0"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.acceleration}
                                                                name={"acceleration"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Разгон до 100 в секундах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Max. Speed, km/h</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 200"}
                                                                className={"input-common"}

                                                                value={equipments[index].performance.speed}
                                                                name={"speed"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Максимальная скорость в км в час"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Transmission Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <select required={true} className={'select-common'}
                                                                    onChange={(event) => {
                                                                        setEquipment(event, index, 'performance')
                                                                    }} name={"transmission_type"}
                                                                    value={equipments[index].performance.transmission_type}>
                                                                <option></option>
                                                                <option>AT</option>
                                                                <option>AT G-Tronic</option>
                                                                <option>MT</option>
                                                                <option>i-MT</option>
                                                                <option>CVT</option>
                                                                <option>e-CVT</option>
                                                                <option>Auto CVT</option>
                                                                <option>DCT</option>
                                                                <option>EDC</option>
                                                                <option>DSG</option>
                                                                <option>Electric</option>
                                                                <option>TCT</option>
                                                                <option>PDK</option>
                                                                <option>LDF</option>
                                                                <option>DualLogic</option>
                                                                <option>Geartronic</option>
                                                                <option>Tiptronic</option>
                                                                <option>S tronic</option>
                                                                <option>Sport Lineartonic</option>
                                                                <option>AMG SPEEDSHIFT DCT</option>
                                                                <option>Tronic</option>
                                                                <option>AMG SPEEDSHIFT TCT</option>
                                                                <option>Multi Stage Hybrid</option>
                                                                <option>Aisin AT</option>
                                                                <option>Dedicated Hybrid Transmission (DHT)</option>
                                                                <option>ZF Automatic</option>
                                                            </select>
                                                            <Tooltip title="Тип коробки передач" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Transmission, number of
                                                            speed</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <select required={true} className={'select-common'}
                                                                    onChange={(event) => {
                                                                        setEquipment(event, index, 'performance')
                                                                    }} name={"gears"}
                                                                    value={equipments[index].performance.gears}>
                                                                <option></option>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                                <option>6</option>
                                                                <option>7</option>
                                                                <option>8</option>
                                                                <option>9</option>
                                                                <option>10</option>
                                                            </select>
                                                            <Tooltip title="Количество ступней КПП" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Driveline</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <select required={true} className={'select-common'}
                                                                    onChange={(event) => {
                                                                        setEquipment(event, index, 'performance')
                                                                    }} name={"driveline"}
                                                                    value={equipments[index].performance.driveline}>
                                                                <option></option>
                                                                <option>AWD</option>
                                                                <option>GR-Four AWD</option>
                                                                <option>i-Activ AWD</option>
                                                                <option>FWD</option>
                                                                <option>RWD</option>
                                                                <option>2WD</option>
                                                                <option>4WD</option>
                                                                <option>4x4</option>
                                                                <option>4x2</option>
                                                                <option>4x2 Hi-Rider (RWD)</option>
                                                                <option>Part-time 4x4</option>
                                                                <option>Full-time 4WD</option>
                                                                <option>Quattro</option>
                                                                <option>4MATIC</option>
                                                            </select>
                                                            <Tooltip title="Привод" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Fuel system</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: Fuel injection"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.fuel_system_name}
                                                                   name={"fuel_system_name"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip
                                                                title="Название топливной системы, например Инжекстор или впрыск"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Recommended octane rating</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <select required={true} className={'select-common'}
                                                                    onChange={(event) => {
                                                                        setEquipment(event, index, 'performance')
                                                                    }} name={"ron"}
                                                                    value={equipments[index].performance.ron}>
                                                                <option></option>
                                                                <option>-1</option>
                                                                <option>91</option>
                                                                <option>95</option>
                                                                <option>98</option>
                                                                <option>E10</option>
                                                                <option>Diesel</option>
                                                            </select>
                                                            <Tooltip title="Рекомендованное топливо" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Combined Fuel Economy, L/100
                                                            km</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 6.6"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.consumption}
                                                                name={"consumption"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Расход в смешанном цикле на 100км"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Fuel capacity, L</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 58"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.tank}
                                                                name={"tank"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Вместимость бака в литрах"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>CO Emissions, g/km</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 58"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.emissions_gkg}
                                                                name={"emissions_gkg"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Выбросы g/km" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Turning circle – kerb to kerb,
                                                            m</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 12"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.turning_circle}
                                                                name={"turning_circle"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Диаметр разворота" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Suspension</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: coil springs"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.suspension_front}
                                                                   name={"suspension_front"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Передняя подвеска" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Suspension</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: leaf springs"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.suspension_back}
                                                                   name={"suspension_back"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Задняя подвеска" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Brakes</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: drum brakes"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.brake_front}
                                                                   name={"brake_front"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Передние тормоза" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Brakes</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: drum brakes"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.brake_back}
                                                                   name={"brake_back"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Задние тормоза" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Wheels</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input
                                                                placeholder={"for example: 16' Dark Anthracite Alloy Wheel"}
                                                                type={"text"} required={true} className={"input-common"}
                                                                value={equipments[index].performance.wheels}
                                                                name={"wheels"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Колеса" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Tyres</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"for example: 265/70 R16"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.tyres}
                                                                   name={"tyres"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Шины" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    {
                                                        equipments[index].performance.additional.map((additional, count) => {
                                                            return (
                                                                <div key={count} style={{
                                                                    display: "flex",
                                                                    marginTop: 10,
                                                                    alignItems: "center"
                                                                }}>
                                                                    <div>
                                                                        <label style={{fontSize: 10}}>Additional</label>
                                                                        <div style={{display: "flex"}}>
                                                                            <input required={true} name={'name'}
                                                                                   value={equipments[index].performance.additional[count].name}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'performance', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white',
                                                                                marginRight: 10
                                                                            }}/>
                                                                            <input required={true} name={'value'}
                                                                                   value={equipments[index].performance.additional[count].value}
                                                                                   onChange={(event) => {
                                                                                       setEquipmentAdditional(event, index, 'performance', count)
                                                                                   }} style={{
                                                                                width: "100%",
                                                                                padding: 6,
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: 6,
                                                                                background: "rgba(255,255,255,0.15)",
                                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                color: 'white'
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                    <Tooltip
                                                                        title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                                        placement="bottom">
                                                                        <QuestionMarkIcon
                                                                            sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                                    </Tooltip>
                                                                    <DeleteIcon sx={{marginTop: 2, marginLeft: 1}}
                                                                                onClick={() => {
                                                                                    let temp = [...equipments];
                                                                                    temp[index].performance.additional.splice(count, 1);
                                                                                    setEquipments(temp);
                                                                                }}/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <Button sx={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        border: "1px solid rgba(255, 255, 255, 0.12)",
                                                        marginTop: 2
                                                    }} onClick={() => {
                                                        let temp = [...equipments];
                                                        temp[index].performance.additional.push({
                                                            name: "",
                                                            value: ""
                                                        });
                                                        setEquipments(temp);
                                                    }}>Add Additional</Button>
                                                </>
                                        }
                                        {
                                            equipments[index].car_fuel_type && equipments[index].car_fuel_type.includes('Hybrid') ?
                                                <div>
                                                    <h4 style={{
                                                        marginTop: 20,
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}><span>Hybrid options</span></h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Motor Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"battery-powered electric motor"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.e_engine_type}
                                                                   name={"e_engine_type"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип электродвигателя" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Hybrid Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"Transmission Mounted Electric Device"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.hybrid_type}
                                                                   name={"hybrid_type"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип гибрида" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Engine Power, hp</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_hp}
                                                                name={"e_engine_hp"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, hp"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Engine Power, kW</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 65"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_kw}
                                                                name={"e_engine_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, kW"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Engine min. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2200"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_min}
                                                                name={"e_engine_rpm_min"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Engine max. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 7700"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_max}
                                                                name={"e_engine_rpm_max"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Electric Engine Power,
                                                            hp</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_hp_front}
                                                                name={"e_engine_hp_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, hp (Передний мотор)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Electric Engine Power,
                                                            kW</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 65"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_kw_front}
                                                                name={"e_engine_kw_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, kW (Передний мотор)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Electric Engine min. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2200"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_min_front}
                                                                name={"e_engine_rpm_min_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Передний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Front Electric Engine max. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 7700"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_max_front}
                                                                name={"e_engine_rpm_max_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Передний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Torque Front, Nm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 230"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_nm_front}
                                                                name={"e_torque_nm_front"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Передний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Electric Engine Power,
                                                            hp</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 120"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_hp_rear}
                                                                name={"e_engine_hp_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, hp (Задний мотор)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Electric Engine Power,
                                                            kW</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 65"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_kw_rear}
                                                                name={"e_engine_kw_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Мощность электромотора, kW (Задний мотор)"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Electric Engine min. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2200"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_min_rear}
                                                                name={"e_engine_rpm_min_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Задний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Rear Electric Engine max. Power,
                                                            rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 7700"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_engine_rpm_max_rear}
                                                                name={"e_engine_rpm_max_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Задний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Torque Rear, Nm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 230"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_nm_rear}
                                                                name={"e_torque_nm_rear"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин (Задний мотор)"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Torque, Nm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 230"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_nm}
                                                                name={"e_torque_nm"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Электрический двигатель мин. Мощность, об/мин"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Min. Torque, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 2200"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_rpm_min}
                                                                name={"e_torque_rpm_min"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Электрический мин. Крутящий момент, об/мин"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Electric Max. Torque, rpm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 3600"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_torque_rpm_max}
                                                                name={"e_torque_rpm_max"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Электрический макс. Крутящий момент, об/мин"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Total System Output: Max. Power,
                                                            Kw</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 270"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_total_power}
                                                                name={"e_total_power"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Общий выход системы: макс. Мощность, кВт"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Total System Output: Max. Torque,
                                                            Kw</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 350"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.e_total_torque}
                                                                name={"e_total_torque"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Общий выход системы: макс. Крутящий момент, кВт"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            equipments[index].car_fuel_type && equipments[index].car_fuel_type.includes('Hybrid') ?
                                                <div>
                                                    <h4 style={{
                                                        marginTop: 20,
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}><span>Hybrid battery options</span></h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"lithium-ion batteries"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.battery_type}
                                                                   name={"battery_type"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип батареи гибрида" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Position</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"under the rear passenger seats"}
                                                                   type={"text"} required={true}
                                                                   className={"input-common"}
                                                                   value={equipments[index].performance.battery_position}
                                                                   name={"battery_position"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Положение батареи" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery Weight, kg</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_weight}
                                                                name={"battery_weight"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Вес батареи, hp" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Voltage, V</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 560"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.voltage}
                                                                name={"voltage"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Напряжение, В" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Capacity, AH</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 4500"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_capacity}
                                                                name={"battery_capacity"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Емкость, Ач" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Energy, Kw</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 4500"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_capacity_kw}
                                                                name={"battery_capacity_kw"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Энергия, кВт" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            equipments[index].car_fuel_type && equipments[index].car_fuel_type === 'Hybrid (PHEV)' || equipments[index].car_fuel_type && equipments[index].car_fuel_type === 'Hybrid (REV)' ?
                                                <div>
                                                    <h4 style={{
                                                        marginTop: 20,
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}><span>{equipments[index].car_fuel_type} options</span></h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Charge Port Type</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <input placeholder={"lithium-ion batteries"} type={"text"}
                                                                   required={true} className={"input-common"}
                                                                   value={equipments[index].performance.charge_port}
                                                                   name={"charge_port"} onWheel={(event) => {
                                                                event.currentTarget.blur()
                                                            }} onChange={(event) => {
                                                                setEquipment(event, index, 'performance')
                                                            }}/>
                                                            <Tooltip title="Тип порта" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>EV Driving Range, km</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 400"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.drive_range}
                                                                name={"drive_range"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="Расстояние на одном заряде, km"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Battery - number of cells</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 560"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.battery_cells}
                                                                name={"battery_cells"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="количество секций в батарее"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Combined Electric Enegy
                                                            consumption, Wh/km</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 560"}
                                                                className={"input-common"}
                                                                value={equipments[index].performance.energy_consumption}
                                                                name={"energy_consumption"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'performance')
                                                                }}
                                                            />
                                                            <Tooltip title="расход батареи Wh/km" placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <h4 style={{marginTop: 20, marginBottom: 5}}>Dimensions, Weights, Towing
                                            capacity </h4>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Length, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat

                                                    placeholder={"for example: 5885"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.length}
                                                    name={"length"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Длина" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Length (with tow receiver), mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 5985"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.length_receiver}
                                                    name={"length_receiver"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Длина c фаркопом" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Width, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat

                                                    placeholder={"for example: 1785"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.width}
                                                    name={"width"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Ширина" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Width (exterior mirrors folded), mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1885"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.width_mirrors}
                                                    name={"width_mirrors"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Ширина со сложенными зеркалами" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Width (with snorkel), mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1895"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.width_snorkel}
                                                    name={"width_snorkel"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Ширина со шноркелем" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Height, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat

                                                    placeholder={"for example: 2195"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.height}
                                                    name={"height"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Высота" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Ground Clearance, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.clearance}
                                                    name={"clearance"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Клиренс" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front Headroom, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.headroom_front}
                                                    name={"headroom_front"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="передняя высота над головой" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front Legroom, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.legroom_front}
                                                    name={"legroom_front"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Переднее пространство для ног, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front Shoulder Room, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.shoulder_front}
                                                    name={"shoulder_front"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Переднее плечевое пространство, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>2nd Row Headroom, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.headroom_2nd_row}
                                                    name={"headroom_2nd_row"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Высота 2-го ряда, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>2nd Row Legroom, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.legroom_2nd_row}
                                                    name={"legroom_2nd_row"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="2-й ряд, пространство для ног, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>2nd Row Shoulder Room, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1250"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.shoulder_2nd_row}
                                                    name={"shoulder_2nd_row"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Ширина плеч 2-го ряда, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Wheelbase, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 2500"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.wheel_base}
                                                    name={"wheel_base"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Колесная база" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front track, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1800"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.front_track}
                                                    name={"front_track"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Передняя колея" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Rear track, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 1800"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.rear_track}
                                                    name={"rear_track"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Задняя колея" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front overhang, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 160"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.overhang_front}
                                                    name={"overhang_front"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Передний свес, mm" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Rear overhang, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 160"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.overhang_back}
                                                    name={"overhang_back"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Задний свес, mm" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Approach angle, °</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.angle_front}
                                                    name={"angle_front"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Угол въезда, °" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Departure angle, °</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.angle_back}
                                                    name={"angle_back"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Угол съезда, °" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Ramp angle, °</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.angle_ramp}
                                                    name={"angle_ramp"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Угол рампы, °" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Wading depth, mm</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 950"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.weding_depth}
                                                    name={"weding_depth"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Глубина брода, мм" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Kerb weight, kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.weight_kerb}
                                                    name={"weight_kerb"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Снаряженная масса, кг" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Gross Vehicle Mass, kg (GVM)</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.weight_car}
                                                    name={"weight_car"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Полная масса автомобиля, кг" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Gross Combined Mass, kg (GCM)</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.weight_full}
                                                    name={"weight_full"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Полная масса, кг" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Front gross axle weight rating, kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.front_axle_weight}
                                                    name={"front_axle_weight"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Полная нагрузка на переднюю ось, кг" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Rear gross axle weight rating, kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.rear_axle_weight}
                                                    name={"rear_axle_weight"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Полная нагрузка на заднюю ось, кг" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Cargo capacity, L</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.cargo_capacity}
                                                    name={"cargo_capacity"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Обьем багажника" placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Cargo capacity (folded 2nd row), L</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.cargo_capacity_2}
                                                    name={"cargo_capacity_2"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Обьем багажника со сложенными 2ым рядом"
                                                         placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Cargo capacity (folded 2nd&3rd row), L</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.cargo_capacity_3}
                                                    name={"cargo_capacity_3"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="Обьем багажника со сложенными 2ым и 3им рядами"
                                                         placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Towing capacity - with trailer brakes,
                                                kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.towing_capacity_brakes}
                                                    name={"towing_capacity_brakes"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="буксируемый вес прицепа с тормозами, кг"
                                                         placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Towing capacity - without trailer brakes,
                                                kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.towing_capacity}
                                                    name={"towing_capacity"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip title="буксируемый вес прицепа без тормозов, кг"
                                                         placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Towing capacity - maximum towball load,
                                                kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.towing_capacity_towball}
                                                    name={"towing_capacity_towball"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip
                                                    title="Тяговое усилие - максимальная нагрузка на шаровую опору, кг"
                                                    placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{marginTop: 10, width: "100%"}}>
                                            <label style={{fontSize: 10}}>Payload based on Kerb weight, kg</label>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <NumericFormat
                                                    placeholder={"for example: 25"}
                                                    className={"input-common"}
                                                    value={equipments[index].dimension.payload}
                                                    name={"payload"}
                                                    type="text" required={true}
                                                    onChange={(event) => {
                                                        setEquipment(event, index, 'dimension')
                                                    }}
                                                />
                                                <Tooltip
                                                    title="Полезная нагрузка в зависимости от снаряженной массы, кг"
                                                    placement="bottom">
                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        {
                                            equipments[index].dimension.additional.map((additional, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <label style={{fontSize: 10}}>Additional</label>
                                                            <div style={{display: "flex"}}>
                                                                <input required={true} name={'name'}
                                                                       value={equipments[index].dimension.additional[count].name}
                                                                       onChange={(event) => {
                                                                           setEquipmentAdditional(event, index, 'dimension', count)
                                                                       }} style={{
                                                                    width: "100%",
                                                                    padding: 6,
                                                                    boxSizing: 'border-box',
                                                                    borderRadius: 6,
                                                                    background: "rgba(255,255,255,0.15)",
                                                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                    color: 'white',
                                                                    marginRight: 10
                                                                }}/>
                                                                <input required={true} name={'value'}
                                                                       value={equipments[index].dimension.additional[count].value}
                                                                       onChange={(event) => {
                                                                           setEquipmentAdditional(event, index, 'dimension', count)
                                                                       }} style={{
                                                                    width: "100%",
                                                                    padding: 6,
                                                                    boxSizing: 'border-box',
                                                                    borderRadius: 6,
                                                                    background: "rgba(255,255,255,0.15)",
                                                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                    color: 'white'
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginTop: 2, marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].dimension.additional.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].dimension.additional.push({
                                                name: "",
                                                value: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Additional</Button>
                                        {
                                            isPickup ?
                                                <div>
                                                    <h4 style={{
                                                        marginTop: 20,
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        width: "100%"
                                                    }}><span>Cargo box dimensions</span></h4>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box height, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 490"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_height}
                                                                name={"cargo_box_height"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Высота грузового отсека, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box floor height from
                                                            ground, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 780"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_floor_height_ground}
                                                                name={"cargo_box_floor_height_ground"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Высота пола грузового отсека от земли, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box length, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1700"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_length}
                                                                name={"cargo_box_length"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Длина грузового отсека, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box floor length, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1600"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_floor_length}
                                                                name={"cargo_box_floor_length"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Длина пола грузового отсека, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box top length, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1750"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_top_length}
                                                                name={"cargo_box_top_length"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Длина грузового отсека по верху, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box width, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1500"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_width}
                                                                name={"cargo_box_width"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Ширина грузового отсека, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box top width, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1500"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_top_width}
                                                                name={"cargo_box_top_width"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Ширина грузового отсека сверху, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box floor width, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1500"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_floor_width}
                                                                name={"cargo_box_floor_width"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip title="Ширина пола грузового отсека, мм"
                                                                     placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box width between wheel
                                                            arches, mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1250"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_width_between_wheel}
                                                                name={"cargo_box_width_between_wheel"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Ширина грузового отсека между колесными арками, мм"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{marginTop: 10, width: "100%"}}>
                                                        <label style={{fontSize: 10}}>Cargo box tailgate opening width,
                                                            mm</label>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <NumericFormat
                                                                placeholder={"for example: 1250"}
                                                                className={"input-common"}
                                                                value={equipments[index].dimension.cargo_box_opening_width}
                                                                name={"cargo_box_opening_width"}
                                                                type="text" required={true}
                                                                onChange={(event) => {
                                                                    setEquipment(event, index, 'dimension')
                                                                }}
                                                            />
                                                            <Tooltip
                                                                title="Ширина проема багажника грузового отсека, мм"
                                                                placement="bottom">
                                                                <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Interior</span> <span>{equipments[index].interior.length}</span></h4>
                                        {
                                            equipments[index].interior.map((additional, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <label style={{fontSize: 10}}>Additional</label>
                                                            <input required={true} name={'value'}
                                                                   value={equipments[index].interior[count].value}
                                                                   onChange={(event) => {
                                                                       setEquipmentAdditional(event, index, 'interior', count)
                                                                   }} style={{
                                                                width: "100%",
                                                                padding: 6,
                                                                boxSizing: 'border-box',
                                                                borderRadius: 6,
                                                                background: "rgba(255,255,255,0.15)",
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                color: 'white'
                                                            }}/>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginTop: 2, marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].interior.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].interior.push({
                                                value: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Additional</Button>
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Comfort, Convenience & Features</span>
                                            <span>{equipments[index].comfort.length}</span></h4>
                                        {
                                            equipments[index].comfort.map((additional, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <label style={{fontSize: 10}}>Additional</label>
                                                            <input required={true} name={'value'}
                                                                   value={equipments[index].comfort[count].value}
                                                                   onChange={(event) => {
                                                                       setEquipmentAdditional(event, index, 'comfort', count)
                                                                   }} style={{
                                                                width: "100%",
                                                                padding: 6,
                                                                boxSizing: 'border-box',
                                                                borderRadius: 6,
                                                                background: "rgba(255,255,255,0.15)",
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                color: 'white'
                                                            }}/>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginTop: 2, marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].comfort.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].comfort.push({
                                                value: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Additional</Button>
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Exterior</span> <span>{equipments[index].exterior.length}</span></h4>
                                        {
                                            equipments[index].exterior.map((additional, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <label style={{fontSize: 10}}>Additional</label>
                                                            <input required={true} name={'value'}
                                                                   value={equipments[index].exterior[count].value}
                                                                   onChange={(event) => {
                                                                       setEquipmentAdditional(event, index, 'exterior', count)
                                                                   }} style={{
                                                                width: "100%",
                                                                padding: 6,
                                                                boxSizing: 'border-box',
                                                                borderRadius: 6,
                                                                background: "rgba(255,255,255,0.15)",
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                color: 'white'
                                                            }}/>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginTop: 2, marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].exterior.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].exterior.push({
                                                value: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Additional</Button>
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Safety</span> <span>{equipments[index].safety.length}</span></h4>
                                        {
                                            equipments[index].safety.map((additional, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <label style={{fontSize: 10}}>Additional</label>
                                                            <input required={true} name={'value'}
                                                                   value={equipments[index].safety[count].value}
                                                                   onChange={(event) => {
                                                                       setEquipmentAdditional(event, index, 'safety', count)
                                                                   }} style={{
                                                                width: "100%",
                                                                padding: 6,
                                                                boxSizing: 'border-box',
                                                                borderRadius: 6,
                                                                background: "rgba(255,255,255,0.15)",
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                color: 'white'
                                                            }}/>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, mt: 2, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginTop: 2, marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].safety.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].safety.push({
                                                value: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Additional</Button>
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Options</span> <span>{equipments[index].options.length}</span></h4>
                                        {
                                            equipments[index].options.map((option, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <div style={{display: "flex"}}>
                                                                <input required={true} name={'name'}
                                                                       value={equipments[index].options[count].name}
                                                                       onChange={(event) => {
                                                                           setEquipmentOptions(event, index, 'options', count)
                                                                       }} style={{
                                                                    width: "100%",
                                                                    padding: 6,
                                                                    boxSizing: 'border-box',
                                                                    borderRadius: 6,
                                                                    background: "rgba(255,255,255,0.15)",
                                                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                    color: 'white',
                                                                    marginRight: 10
                                                                }}/>
                                                                <NumericFormat
                                                                    className={"input-common"}

                                                                    value={equipments[index].options[count].cost}
                                                                    name={"cost"}
                                                                    type="text" required={true}
                                                                    thousandSeparator=" "
                                                                    onChange={(event) => {
                                                                        setEquipmentOptions(event, index, 'options', count)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Tooltip
                                                            title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                            placement="bottom">
                                                            <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                        </Tooltip>
                                                        <DeleteIcon sx={{marginLeft: 1}} onClick={() => {
                                                            let temp = [...equipments];
                                                            temp[index].options.splice(count, 1);
                                                            setEquipments(temp);
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].options.push({
                                                name: "",
                                                cost: ""
                                            });
                                            setEquipments(temp);
                                        }}>Add Option</Button>
                                        <h4 style={{
                                            marginTop: 20,
                                            marginBottom: 5,
                                            display: 'flex',
                                            justifyContent: "space-between",
                                            width: "100%"
                                        }}><span>Packages</span> <span>{equipments[index].packages.length}</span></h4>
                                        {
                                            equipments[index].packages.map((option, count) => {
                                                return (
                                                    <div key={count}
                                                         style={{display: "flex", marginTop: 10, alignItems: "center"}}>
                                                        <div>
                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                <input required={true} name={'name'}
                                                                       value={equipments[index].packages[count].name}
                                                                       onChange={(event) => {
                                                                           setEquipmentOptions(event, index, 'packages', count)
                                                                       }} style={{
                                                                    width: "100%",
                                                                    padding: 6,
                                                                    boxSizing: 'border-box',
                                                                    borderRadius: 6,
                                                                    background: "rgba(255,255,255,0.15)",
                                                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                    color: 'white',
                                                                    marginRight: 10
                                                                }}/>
                                                                <NumericFormat
                                                                    className={"input-common"}

                                                                    value={equipments[index].packages[count].cost}
                                                                    name={"cost"}
                                                                    type="text" required={true}
                                                                    thousandSeparator=" "
                                                                    onChange={(event) => {
                                                                        setEquipmentOptions(event, index, 'packages', count)
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                                    placement="bottom">
                                                                    <QuestionMarkIcon sx={{height: 15, marginLeft: 1}}/>
                                                                </Tooltip>
                                                                <DeleteIcon sx={{marginLeft: 1}} onClick={() => {
                                                                    let temp = [...equipments];
                                                                    temp[index].packages.splice(count, 1);
                                                                    setEquipments(temp);
                                                                }}/>
                                                            </div>
                                                            <div style={{
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                marginTop: 10,
                                                                padding: 5,
                                                                borderRadius: 6
                                                            }}>
                                                                {equipments[index].packages[count].additional && equipments[index].packages[count].additional.map((additional, index_pac) => {
                                                                    return (
                                                                        <div key={index} style={index_pac === 0 ? {
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        } : {
                                                                            display: "flex",
                                                                            marginTop: 10,
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <div>
                                                                                <label
                                                                                    style={{fontSize: 10}}>Additional</label>
                                                                                <input required={true} name={'value'}
                                                                                       value={equipments[index].packages[count].additional[index_pac].value}
                                                                                       onChange={(event) => {
                                                                                           setEquipmentPackagesAdditional(event, index, 'packages', count, index_pac)
                                                                                       }} style={{
                                                                                    width: "100%",
                                                                                    padding: 6,
                                                                                    boxSizing: 'border-box',
                                                                                    borderRadius: 6,
                                                                                    background: "rgba(255,255,255,0.15)",
                                                                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                                    color: 'white',
                                                                                    marginRight: 10
                                                                                }}/>
                                                                            </div>
                                                                            <Tooltip
                                                                                title='Сначала даю название Характеристики, потом значение. В качестве значения может быть цифра "-2" это означает, что будет точка перед текстом'
                                                                                placement="bottom">
                                                                                <QuestionMarkIcon sx={{
                                                                                    height: 15,
                                                                                    mt: 2,
                                                                                    marginLeft: 1
                                                                                }}/>
                                                                            </Tooltip>
                                                                            <DeleteIcon
                                                                                sx={{marginTop: 2, marginLeft: 1}}
                                                                                onClick={() => {
                                                                                    let temp = [...equipments];
                                                                                    temp[index].packages[count].additional.splice(index_pac, 1);
                                                                                    setEquipments(temp);
                                                                                }}/>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                            <Button sx={{
                                                                textTransform: "none",
                                                                color: "white",
                                                                border: "1px solid rgba(255, 255, 255, 0.12)",
                                                                marginTop: 2,
                                                                width: "100%"
                                                            }} onClick={() => {
                                                                let temp = [...equipments];
                                                                temp[index].packages[count].additional.push({
                                                                    value: ""
                                                                });
                                                                setEquipments(temp);
                                                            }}>Add Additional</Button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Button sx={{
                                            textTransform: "none",
                                            color: "white",
                                            border: "1px solid rgba(255, 255, 255, 0.12)",
                                            marginTop: 2
                                        }} onClick={() => {
                                            let temp = [...equipments];
                                            temp[index].packages.push({
                                                name: "",
                                                cost: "",
                                                additional: []
                                            });
                                            setEquipments(temp);
                                        }}>Add Package</Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </form>
    )
}
