import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router";
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, Button, IconButton, Modal, Snackbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useContext, useEffect, useState} from "react";
import Moment from 'moment';
import axios from "axios";
import AuthContext from "../../context/AuthProvider";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: '#121212',
    height:130,
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius:2,
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between"
};

export const Main = () => {
    const history = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setsnackbarMessage] = useState('');
    const [openNewBrand, setOpenNewBrand] = useState(false);
    const [deleteBrand, setDeleteBrand] = useState(false);
    const [brands, setBrands] = useState([]);
    const [deleteID, setDeleteID] = useState({
        brand_name:''
    });
    const [newBrand, setNewBrand] = useState({
        brand_name:''
    });
    const [error, setError] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleOpenCreateBrand = () => {
        setOpenNewBrand(true)
    };
    const handleCloseNewBrand = () => {
        setNewBrand({brand_name: ""});
        setOpenNewBrand(false);
        setError('');
    };
    const handleopenDeleteBrand = (brand_name) => {
        setDeleteID({brand_name: brand_name});
        setDeleteBrand(true);
    };
    const handlecloseDeleteBrand = () => {
        setDeleteBrand(false)
    };

    const deleteBrandAction = () => {
        axios.post("https://backend.newozcars.com/cars/delete_brand/", {"brand_name": deleteID.brand_name}).then(
            res=>{
                setsnackbarMessage(res.data.message);
                handlecloseDeleteBrand();
                axios.get("https://backend.newozcars.com/cars/brands_list/").then(
                    res => {
                        setBrands(res.data);
                        setSnackbarOpen(true);
                    }
                ).catch(
                    err => {
                        console.log(err)
                    }
                )
            }
        ).catch(
            err=>{console.log(err)}
        )
    }
    const confirmBrand = () => {
        axios.post("https://backend.newozcars.com/cars/create_brand/", {...newBrand}).then(
            res=>{
                setsnackbarMessage(res.data.message);
                handleCloseNewBrand()
                axios.get("https://backend.newozcars.com/cars/brands_list/").then(
                    res => {
                        setBrands(res.data);
                        setSnackbarOpen(true);
                    }
                ).catch(
                    err => {
                        console.log(err);
                    }
                )}
        ).catch(
            err=>{console.log(err); setError(err.response.data.message)}
        )
    }
    const snackbarData = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    useEffect(() => {
        axios.get("https://backend.newozcars.com/cars/brands_list/").then(
            res => {
                setBrands(res.data);
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }, [])
    return(
        <div style={{position:"relative"}}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message={`${snackbarMessage}`}
                action={snackbarData}
            />
            <div style={{display:'flex', maxWidth:'1200px', width:"100%", justifyContent:'space-between', boxSizing:"border-box", position:"fixed", top:50, paddingTop:10, paddingBottom:10, background:"#121212", zIndex:2}}>
                <p style={{marginTop:8, marginBottom:8, marginLeft:22}}>Main</p>
                <Button onClick={handleOpenCreateBrand} sx={{background:"none", textTransform:"none", color:"white", border:"1px solid rgba(255, 255, 255, 0.12)", marginRight:3}}>New Brand</Button>
                <Modal
                    open={openNewBrand}
                    sx={{background:"black"}}
                    onClose={handleCloseNewBrand}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h2 style={{margin:0, fontSize:14, fontWeight:400, textAlign:"center"}}>Create New Brand</h2>
                        <div style={{display:"flex", flexDirection:"column", width:'100%', fontSize:14}}>
                            <p style={{color:"red", margin:0, height:20}}>{error}</p>
                            <input onChange={(event)=>{setNewBrand({...newBrand, brand_name: event.target.value}); setError("");}} placeholder={"brand name"} name={"login"} style={{color:"white", padding:"5px", background:"none", border:"1px solid rgba(255, 255, 255, 0.12)", marginBottom:20, borderRadius:4}}/>
                        </div>
                        <Button
                            onClick={confirmBrand}
                            sx={{
                                width:"100%",
                                background:"rgba(255, 255, 255, 0.08)",
                                color:"white",
                                textTransform:"none",
                                fontSize:14,
                                fontWeight:400
                            }}
                        >Confirm</Button>
                    </Box>
                </Modal>
                <Modal
                    open={deleteBrand}
                    sx={{background:"black"}}
                    onClose={handlecloseDeleteBrand}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h2 style={{margin:0, fontSize:14, fontWeight:400, textAlign:"center"}}>Delete Brand - {deleteID.brand_name}. Are you sure?</h2>
                        <Button
                            onClick={()=>{deleteBrandAction()}}
                            sx={{
                                width:"100%",
                                background:"red",
                                color:"white",
                                textTransform:"none",
                                fontSize:14,
                                fontWeight:400,
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.12) !important"
                                }
                            }}
                        >Delete</Button>
                        <Button
                            onClick={handlecloseDeleteBrand}
                            sx={{
                                width:"100%",
                                background:"rgba(255, 255, 255, 0.12)",
                                color:"white",
                                textTransform:"none",
                                fontSize:14,
                                fontWeight:400,

                            }}
                        >Close</Button>
                    </Box>
                </Modal>
            </div>

            <div style={{overflow:"auto", marginRight:8, marginLeft:8, marginTop:80, boxSizing:'border-box', border:'1px solid rgba(255, 255, 255, 0.12)', borderRadius:6, color:'white'}}>
                <TableContainer component={Paper} sx={{background:"none", border:"none"}}>
                    <Table sx={{ minWidth: 650, background:"none" }} aria-label="simple table" >
                        <TableHead>
                            <TableRow sx={{borderBottom: "2px solid rgba(255, 255, 255, 0.12)",}}>
                                <TableCell sx={{color:"white"}}>ID</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Brand</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Model Count</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Last Update</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Created at</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Created by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {brands.map((brand) => (
                                <TableRow
                                    hover
                                    key={brand.id}
                                    sx={{ borderBottom: "2px solid rgba(255, 255, 255, 0.12)", '&:last-child td, &:last-child th': { border: 0 }, "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.12) !important"
                                        } }}
                                >
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} component="th" scope="row">
                                        {brand.id}
                                    </TableCell>
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} align="center">{brand.name}</TableCell>
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} align="center">{brand.model_count}</TableCell>
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} align="center">{Moment(brand.updated_at).format("DD.MM.YYYY hh:mm")}</TableCell>
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} align="center">{Moment(brand.created_at).format("DD.MM.YYYY hh:mm")}</TableCell>
                                    <TableCell onClick={()=>{history(`/${brand.id}/${brand.name}`)}} sx={{color:"white"}} align="center">{brand.author}</TableCell>
                                    {/*<TableCell sx={{color:"white"}} align="center" onClick={()=>{handleopenDeleteBrand(brand.name)}}><Button disabled={true} sx={{color:"white"}}><DeleteIcon/></Button></TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
