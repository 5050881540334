import {useContext, useEffect} from "react";
import AuthContext, {AuthProvider} from "./context/AuthProvider";
import {BrowserRouter as Router} from 'react-router-dom';
import {useRoutes} from "./routes";
import {Header} from "./components/Header";
import axios from "axios";
function App() {
    const {auth, me, setMe} = useContext(AuthContext);
    const routes = useRoutes(auth);
    return (
        <>
            {auth && <Header/>}
            <div style={auth ? {marginTop:120, maxWidth:1200} : {marginTop:0, maxWidth:1200}}>
                <Router>
                    {routes}
                </Router>
            </div>
        </>
    )
}
export default App;
