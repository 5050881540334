import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {Box, Button, IconButton, Modal, Snackbar} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import AuthContext from "../../context/AuthProvider";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: '#121212',
    height:130,
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius:2,
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between"
};

export const Model = () => {
    const history = useNavigate();
    const params = useParams();
    const {setPhotos, setPreview} = useContext(AuthContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setsnackbarMessage] = useState('');
    const [deleteModelOpen, setDeleteModelOpen] = useState(false);
    const [deleteID, setDeleteID] = useState({
        model_name:""
    });
    const [models, setModels] = useState([]);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleopenDeleteModel = model_name => {
        setDeleteID({model_name:model_name})
        setDeleteModelOpen(true)
    };
    const handlecloseDeleteModel = () => {
        setDeleteModelOpen(false)
    };
    const deleteModelAction = () => {
        axios.post(`https://backend.newozcars.com/cars/delete_model/`, {model_name: deleteID.model_name}).then(
            res => {
                setsnackbarMessage(res.data.message);
                axios.get(`https://backend.newozcars.com/cars/models_list/${params.brand_id}/`).then(
                    res=>{
                        setModels(res.data);
                        setSnackbarOpen(true);
                        setDeleteModelOpen(false);
                    }
                ).catch(
                    err=>{
                        console.log(err)
                    }
                )
                console.log(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
        setDeleteModelOpen(false)
    };
    const snackbarData = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    useEffect(() => {
        setPhotos([]);
        setPreview([]);
        axios.get(`https://backend.newozcars.com/cars/models_list/${params.brand_id}/`).then(
            res=>{
                setModels(res.data);
            }
        ).catch(
            err=>{
                console.log(err)
            }
        )
    },[])
    return(
        <div style={{position:"relative"}}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message={`${snackbarMessage}`}
                action={snackbarData}
            />
            <div style={{display:'flex', maxWidth:'1200px', width:"100%", justifyContent:'space-between', boxSizing:"border-box", position:"fixed", top:50, paddingTop:10, paddingBottom:10, background:"#121212", zIndex:2}}>
                <p style={{marginTop:8, marginBottom:8, marginLeft:22}}><span onClick={()=>{history("/")}} style={{color:"white", cursor:"pointer", textDecoration:"underline"}}>Main</span> / {params.brand_name}<span></span></p>
                <Button onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/new_model`)}} sx={{background:"none", textTransform:"none", color:"white", border:"1px solid rgba(255, 255, 255, 0.12)", marginRight:3}}>New Model</Button>
            </div>
            <Modal
                open={deleteModelOpen}
                sx={{background:"black"}}
                onClose={handlecloseDeleteModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 style={{margin:0, fontSize:14, fontWeight:400, textAlign:"center"}}>Delete Model - {deleteID.model_name} from {params.brand_name}. Are you sure?</h2>
                    <Button
                        onClick={()=>{deleteModelAction()}}
                        sx={{
                            width:"100%",
                            background:"red",
                            color:"white",
                            textTransform:"none",
                            fontSize:14,
                            fontWeight:400,
                            "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.12) !important"
                            }
                        }}
                    >Delete</Button>
                    <Button
                        onClick={handlecloseDeleteModel}
                        sx={{
                            width:"100%",
                            background:"rgba(255, 255, 255, 0.12)",
                            color:"white",
                            textTransform:"none",
                            fontSize:14,
                            fontWeight:400,

                        }}
                    >Close</Button>
                </Box>
            </Modal>
            <div style={{overflow:"auto", marginRight:8, marginLeft:8, marginTop:80, boxSizing:'border-box', border:'1px solid rgba(255, 255, 255, 0.12)', borderRadius:6, color:'white'}}>
                <TableContainer component={Paper} sx={{background:"none", border:"none"}}>
                    <Table sx={{ minWidth: 650, background:"none" }} aria-label="simple table" >
                        <TableHead>
                            <TableRow sx={{borderBottom: "2px solid rgba(255, 255, 255, 0.12)",}}>
                                <TableCell sx={{color:"white"}}>ID</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Model</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Grades Count</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Last Update</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Created at</TableCell>
                                <TableCell sx={{color:"white"}} align="center">Created by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {models.map((model) => (
                                <TableRow
                                    hover
                                    key={model.id}
                                    sx={{ borderBottom: "2px solid rgba(255, 255, 255, 0.12)", '&:last-child td, &:last-child th': { border: 0 }, "&:hover": {
                                            backgroundColor: "rgba(255, 255, 255, 0.12) !important"
                                        } }}
                                >
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} component="th" scope="row">
                                        {model.id}
                                    </TableCell>
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} align="center">{model.name}</TableCell>
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} align="center">{model.grade_count}</TableCell>
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} align="center">{Moment(model.updated_at).format("DD.MM.YYYY hh:mm")}</TableCell>
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} align="center">{Moment(model.created_at).format("DD.MM.YYYY hh:mm")}</TableCell>
                                    <TableCell onClick={()=>{history(`/${params.brand_id}/${params.brand_name}/${model.id}/${model.name}`)}} sx={{color:"white"}} align="center">{model.author}</TableCell>
                                    <TableCell sx={{color:"white"}} align="center" onClick={()=>{handleopenDeleteModel(model.name)}}><Button sx={{color:"white"}}><DeleteIcon/></Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
