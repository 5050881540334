import React, {useState} from 'react';
import {Button} from "@mui/material";
import axios from "axios";

export const Login = () => {
    const [data, setData] = useState({
        username:"",
        password:"",
    })
    const [error, setError] = useState(false);
    const onChange = (event) => {
        setError(false)
        setData({...data, [event.target.name]: event.target.value});
    }
    const confirm = () => {
        axios.post('https://backend.newozcars.com/auth/', {...data}).then(
            res => {
                localStorage.setItem('ozcarsToken', res.data.token);
                window.location.reload();
            }
        ).catch(err => {setError(true)})
    }
    return(
        <div style={{height:'100vh', display:'flex', alignItems:"center", justifyContent:"center", position:"relative",background:'#121212'}}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", padding:10, background:"rgba(255, 255, 255, 0.08)", marginTop:"-150px", borderRadius:4, border:'1px solid rgba(255, 255, 255, 0.12)', width:"300px", height:'200px'}}>
                <h2 style={{margin:0, fontSize:14, fontWeight:400, textAlign:"center"}}>Login</h2>
                <div style={{display:"flex", flexDirection:"column", width:'100%', fontSize:14}}>
                    <input name={"username"} onChange={onChange} style={{color:"white", padding:"5px", background:"none", border:"1px solid rgba(255, 255, 255, 0.12)", borderRadius:4}}/>
                    <input name={"password"} onChange={onChange} type={"password"} style={{marginTop:"20px", color:"white", padding:"5px", background:"none", border:"1px solid rgba(255, 255, 255, 0.12)", borderRadius:4}}/>
                </div>
                <p style={error ? {margin:0, fontSize:10, color:"red"} : {opacity:0, margin:0, fontSize:10, color:"red"}}>Wrong Login or Password</p>
                <Button
                    onClick={confirm}
                    sx={{
                        width:"100%",
                        background:"rgba(255, 255, 255, 0.08)",
                        color:"white",
                        textTransform:"none",
                        fontSize:14,
                        fontWeight:400
                    }}
                >Confirm</Button>
            </div>
            <div style={{position:"absolute", bottom:0, background:"rgba(255, 255, 255, 0.08)", width:"100%", padding:5, boxSizing:"border-box",borderTop:'1px solid rgba(255, 255, 255, 0.12)'}}>
                <p style={{margin:0, fontSize:12, color:"rgba(255,255,255,0.49)"}}>all rights reserved TDSMedia 2023</p>
            </div>
        </div>
    )
}
